<template>
  <section class="m-user-coupon m-scroll">
    <!-- 头部导航 -->
    <m-header title="钱包明细"></m-header>
    <!-- 优惠券列表 -->
    <van-list  v-model="loading" :finished="finished" finished-text="没有更多记录了" @load="onLoad" class="scroll-list">
      <div class="item" v-for="(it,index) in list" :key="index">
        <div class="item-left">
          <p>
            <span v-if="it.detailed_describe == 0">订单奖励</span>
            <span v-if="it.detailed_describe == 1">提现</span>
            <span v-if="it.detailed_describe == 2">售后扣除</span>
          </p>
          <p v-if="it.detailed_describe != 1" class="order-num">
            <span v-if="it.detailed_describe == 0">{{it.item.title}}</span>
            <span v-if="it.detailed_describe == 2">{{it.item.order.title}}</span>
          </p>
          <p class="time">{{it.created_at}}</p>
        </div>
        <div class="item-right">
          <p v-if="it.item">
            <span class="status0" v-if="it.item.audit_status == 0">待审核</span>
            <span class="status1" v-if="it.item.audit_status == 1 && it.item.transfer_status == 0">已通过,未到账</span>
            <span class="status2" v-if="it.item.transfer_status == 1 && it.item.transfer_status == 1">已到账</span>
            <span class="status-1" v-if="it.item.audit_status == -1">已拒绝</span>
          </p>
          <p><span>{{it.detailed_type == 0 ? '-' : '+'}}</span>${{it.amount}}</p>
        </div>
        <!-- <van-cell center :title="it.detailed_describe_title" :value="`$${it.amount}`" :label="it.created_at" /> -->
      </div>
    </van-list>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { agent_record } from '@/api/zh/mine.js'
export default {
  name:'WalletRecord',
  components:{ MHeader },
  data(){
    return {
      list: [],
      page: 0,
      loading: false,
      finished: false,
    }
  },
  methods:{
    getData() {
      let params = { page: this.page  }
      agent_record(params).then(res => {
        if(res) {
          this.list = [...this.list, ...res.data.data]
          this.loading = false
          if(res.data.last_page <= this.page) {
            this.finished = true
          }
        }else {
          this.finished = true
        }
      })
    },
    onLoad() {
      this.page ++ 
      this.getData()
    }
  },
  created() {
    // this.getData()
  },


}
</script>

<style lang="less" scoped>
@import './record.less';
</style>